<script>
export default {
    name: 'Home',
}
</script>

<template>
    <!-- 初始界面 -->
    <div class="first">
        <span>Welcome to Your Music App </span>
        <span>By Tiam</span>
        <span>HBNU-计信2014</span>
        <span>---【2022-12-6 10:18:23 星期二】</span>
    </div>
</template>

<style scoped>
.first>span {
    display: block;
    margin-bottom: 10px;
}
</style>